// DO NOT EDIT THIS FILE !!!! GENERATED DURING BUILD TYPE : Ansible
export const DOL_VERSION='[master]7.0-rc-11493(beff799171)-C03/04/2025-15:39:46-B03/04/2025-15:44:22' ;
export const DOL_VERSION_JSON = {
  dolVersion: "[master]7.0-rc-11493(beff799171)-C03/04/2025-15:39:46-B03/04/2025-15:44:22",
  branch: "master",
  latestTag: "7.0-rc",
  revCount: "11493",
  shortHash: "beff799171",
  longHash: "beff7991712b759407621d07114d7cbb5bde6e26",
  dateCommit: "03/04/2025-15:39:46",
  dateBuild: "03/04/2025-15:44:22",
  buildType: "Ansible",
  } ;
